import React from "react";

import domains from "../../../configuration/domains";
import Heading from "../../atoms/Heading";
import Page from "../../organisms/Page";

const { imageCdnUrlOpt } = domains;

import * as p from "../../organisms/Page/styles.module.scss";

export default function NotFound() {
    return (
        <Page
            metaTitle="404"
            heroImages={[
                `${imageCdnUrlOpt}/Milky_Way/Milky_Way_Iria_0818_S_eyhhfq.jpg`, // 768 Mobile
                `${imageCdnUrlOpt}/Milky_Way/Milky_Way_Iria_0818_M_y52spo.jpg`, // 992 Tablet
                `${imageCdnUrlOpt}/Milky_Way/Milky_Way_Iria_0818_L_knukad.jpg`, // 2880 Desktop
            ]}
            pageView={"/404"}
        >
            <div className={p.containerWrapper}>
                <div className={p.container}>
                    <Heading level={1} size="large" className={p.heading}>
                        404
                    </Heading>
                    <p>Welcome to the void. Sorry, nothing to see here.</p>
                </div>
            </div>
        </Page>
    );
}
